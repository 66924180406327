import { SigleStep } from "../SigleStep";
import { SigleStepOng } from "../SigleStepOng";
import { AdresseOneStep } from "../AdresseOneStep";
import { AdresseTwoStep } from "../AdresseTwoStep";
import { Associes } from "../Associes";
import { AssociesUni } from "../AssociesUni";
import { AssocieTypes } from "../AssocieTypes";
import { CapitalStep } from "../CapitalStep";
import { ActiviteStep } from "../ActiviteStep";
import { GerantStep } from "../GerantStep";
import { PresidentStep } from "../PresidentStep_ci_sn_bf_bn";
import { PresidentOng } from "../PresidentOng";
import { DirecteurStep } from "../DirecteurSteps_ci_sn_bf_bn";
import { SecretaireGeneral } from "../SecretaireGeneral";
import { Bureau } from "../Bureau";
import { Tresorier } from "../Tresorier";
import { Commissaire } from "../Commissaire";
import { CommissaireAdjoint } from "../CommissaireAdjoint";
import { Dure } from "../Dure";
import { Montant } from "../Montant";
import { RecapStep } from "../RecapStep";
import { RecapStepOng } from "../RecapStepOng";
import { RecapStepSas } from "../RecapStepSas";
import { RecapStepSarlV2 } from "./sarl/RecapStepSarlV2";
import { RecapStepSasV2 } from "./sarl/RecapStepSasV2";
import { RecapStepSci } from "./sci/RecapStepSci";
import { SigleStepRecouvre } from "./recouvrement/SigleStepRecouvre";
import { AdresseOneStepRecouvre } from "./recouvrement/AdresseOneStepRecouvre";
import { AdresseTwoStepRecouvre } from "./recouvrement/AdresseTwoStepRecouvre";
import { FacturesRecouvre } from "./recouvrement/FacturesRecouvre";
import { RecapStepRecouvre } from "./recouvrement/RecapStepRecouvre";
import { CommentRecouvre } from "./recouvrement/CommentRecouvre";
// import { SigleStepStatutaire } from "./statutaire/SigleStepStatutaire";
// import { AdresseOneStepStatutaire } from "./statutaire/AdresseOneStepStatutaire";
// import { AdresseTwoStepStatutaire } from "./statutaire/AdresseTwoStepStatutaire";
// import { AssociesStatutaire } from "./statutaire/AssociesStatutaire";
import { InfoModification } from "./statutaire/InfoModification";
import { PresidentStepStatutaire } from "./statutaire/PresidentStepStatutaire";
import { RecapStepStatutaire } from "./statutaire/RecapStepStatutaire";
import { AssociesStatutaire } from "./statutaire/AssociesStatutaire";
import { InfoAssemble } from "./statutaire/InfoAssemble";
export const CI = {
  SARL: {
    SigleStep,
    AdresseOneStep,
    AdresseTwoStep,
    Associes,
    CapitalStep,
    ActiviteStep,
    GerantStep,
    RecapStepSarl: RecapStep,
    RecapStepSarlV2,
  },
  SARLU: {
    SigleStep,
    AdresseOneStepSarlUni: AdresseOneStep,
    AdresseTwoStepSarlUni: AdresseTwoStep,
    AssociesSarlUni: AssociesUni,
    AssocieTypesSarlUni: AssocieTypes,
    CapitalStep,
    ActiviteStepSarlUni: ActiviteStep,
    GerantStep,
    RecapStepUni: RecapStep,
    RecapStepSarlV2,
  },
  SASU: {
    SigleStep,
    AdresseOneStepSasUni: AdresseOneStep,
    AdresseTwoStepSasUni: AdresseTwoStep,
    AssociesSasUni: AssociesUni,
    AssocieTypesSasUni: AssocieTypes,
    ActiviteStepSasUni: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSasV2,
  },
  SAS: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSasV2,
  },
  SCOOP: {
    SigleStepAssociation: SigleStepOng,
    AdresseOneStepAssociation: AdresseOneStep,

    AdresseTwoStepAssociation: AdresseTwoStep,
    Associes,
    CapitalStep,

    PresidentAssociation: PresidentOng,
    SecretaireGeneralAssociation: SecretaireGeneral,
    ActiviteStepAssociation: ActiviteStep,
    TresorierAssociation: Tresorier,
    BureauAssociation: Bureau,
    CommissaireAssociation: Commissaire,
    CommissaireAssociationAdjoint: CommissaireAdjoint,
    DureAssociation: Dure,
    MontantAssociation: Montant,
    RecapStepAssopciation: RecapStepOng,
    RecapStepSarlV2,
  },
  individuelle: {
    SigleStep,
    AdresseOneStep,
    AdresseTwoStep,
    Associes,
    // CapitalStep,
    ActiviteStep,
    // GerantStep,
    RecapStepSarl: RecapStep,
    RecapStepSarlV2,
  },
  commerciale: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSci,
  },
  civile: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSci,
  },
  statutaire: {
    // SigleStep: SigleStepStatutaire,
    AssociesStatutaire: AssociesStatutaire,
    InfoAssemble: InfoAssemble,
    // Associes: AssociesStatutaire,
    // ActiviteStepSas: ActiviteStep,
    // CapitalStep,
    InfoModification: InfoModification,
    PresidentStep: PresidentStepStatutaire,
    // DirecteurStep,
    // RecapStep: RecapStepSas,
    RecapStepStatutaire: RecapStepStatutaire,
  },
  Association: {
    SigleStepAssociation: SigleStepOng,
    AdresseOneStepAssociation: AdresseOneStep,
    AdresseTwoStepAssociation: AdresseTwoStep,
    PresidentAssociation: PresidentOng,
    SecretaireGeneralAssociation: SecretaireGeneral,
    ActiviteStepAssociation: ActiviteStep,
    TresorierAssociation: Tresorier,
    BureauAssociation: Bureau,
    CommissaireAssociation: Commissaire,
    CommissaireAssociationAdjoint: CommissaireAdjoint,
    DureAssociation: Dure,
    MontantAssociation: Montant,
    RecapStepAssopciation: RecapStepOng,
    RecapStepSarlV2,
  },
  Fondation: {
    SigleStepFondation: SigleStepOng,
    ActiviteStepFondation: ActiviteStep,
    AdresseOneStepFondation: AdresseOneStep,
    AdresseTwoStepFondation: AdresseTwoStep,
    PresidentFondation: PresidentOng,
    SecretaireGeneralFondation: SecretaireGeneral,
    TresorierFondation: Tresorier,
    BureauFondation: Bureau,
    CommissaireFondation: Commissaire,
    CommissaireFondationAdjoint: CommissaireAdjoint,
    DureFondation: Dure,
    MontantFondation: Montant,
    RecapStepFondation: RecapStepOng,
    RecapStepSarlV2,
  },
  ONG: {
    SigleStepOng: SigleStepOng,
    ActiviteStepOng: ActiviteStep,
    AdresseOneStepOng: AdresseOneStep,
    AdresseTwoStepOng: AdresseTwoStep,
    PresidentOng,
    SecretaireGeneralOng: SecretaireGeneral,
    TresorierOng: Tresorier,
    BureauOng: Bureau,
    CommissaireOng: Commissaire,
    CommissaireOngAjoint: CommissaireAdjoint,
    DureOng: Dure,
    MontantOng: Montant,
    RecapStepOng,
    RecapStepSarlV2,
  },
  Recouvrement: {
    SigleStepRecouvre: SigleStepRecouvre,
    // ActiviteStepRecouv: ActiviteStep,
    AdresseOneStepRecouvre: AdresseOneStepRecouvre,
    AdresseTwoStepRecouvre: AdresseTwoStepRecouvre,
    FacturesRecouvre: FacturesRecouvre,
    CommentRecouvre: CommentRecouvre,
    RecapStepRecouvre: RecapStepRecouvre,
    RecapStepSarlV2,
  },
};
