import React, { useEffect, useState } from "react";
// import { Components } from "..";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Services } from "../../services";
import { useParams } from "react-router-dom";

function CheckoutForm(props) {
  // console.log("checkout", props);
  const { id } = useParams();
  // console.log("id", id);

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [disabled, setDisabled] = useState(true);

  const CURRENCY = props.currency !== "FCFA" ? props.currency : "xof";
  const {
    dataUse,
    demande,
    stripe,
    elements,
    usePaiement,
    handlePaymentSubmit,
  } = props;
  // console.log("usePaiement1", usePaiement);

  const getPaymentIntent = async () => {
    const payment_data = {
      demande_id: demande ? demande : parseInt(id),
      montant: parseInt(usePaiement.montant),
      currency: CURRENCY,
      description: "This is test payment",
      payment_data: JSON.stringify({
        demande_id: demande ? demande : parseInt(id),
        montant: parseInt(usePaiement.montant),
        currency: CURRENCY,
      }),
    };
    if (dataUse) {
      const { client_secret } =
        await Services.PaiementService.getPaymentIntents(
          dataUse,
          JSON.stringify(payment_data),
          AbortController.signal
        );
      setClientSecret(client_secret);
    } else {
      const { client_secret } = await Services.PaiementService.getPaymentIntent(
        JSON.stringify(payment_data),
        AbortController.signal
      );
      setClientSecret(client_secret);
    }

    // console.log("getPaymentIntent", client_secret);
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getPaymentIntent();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    usePaiement.setIsDisabled(true);
    // console.log("clientSecret");

    // if (!stripe || !elements || usePaiement.isDisabled) return;

    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      // console.log("payload", payload);

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
      }
      //   // const card = elements.getElement(CardElement);
      //   // const result = await stripe.createToken(card);

      //   // if (result.error) throw new Error(result.error.message);

      const payment_datas = {
        // source: result.token.id,
        payment_intent_id: payload.paymentIntent.id,
        amount: usePaiement.montant,
        currency: CURRENCY,
        description: "This is test payment",
      };
      // console.log("payment_datas", payment_datas);

      handlePaymentSubmit(e, payment_datas);
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //toast
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  // console.log("usePaiement2", usePaiement);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div>
      <h3
        className="text-gray-600 font-light mb-3"
        style={{ fontSize: "1.4rem" }}
      >
        {usePaiement.montant} {CURRENCY === "XOF" ? "FCFA" : CURRENCY}
      </h3>
      {/* {clientSecret ? ( */}
      <form onSubmit={handleSubmit}>
        <CardElement options={cardStyle} onChange={handleChange} />
        <button
          className="btn-pay"
          disabled={processing || disabled || succeeded}
        >
          {usePaiement.isDisabled ? "Paiement en cours..." : "Payer maintenant"}
        </button>
      </form>
      {/* ) : (
        <div className="font-bold text-gray-600">
          Nous avons un soucie Veuillez réesayer
        </div>
      )} */}
    </div>
  );
}

export function InjectedCheckoutForm(props) {
  // console.log("InjectedCheckoutForm", props);

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          demande={props.demande}
          stripe={stripe}
          dataUse={props.dataUse}
          elements={elements}
          handlePaymentSubmit={props.handlePaymentSubmit}
          usePaiement={props.usePaiement}
          currency={props.currency}
        />
      )}
    </ElementsConsumer>
  );
}
