import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Data } from "../data";
import { Services } from "../services";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CinetPaySerivce } from "../services/CinetpayService";
import { Utils } from "../utils";
import { dataDataGreffeRccm } from "../services/DatagreffeService";
import Spinner from "../components/Spinner";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

export function DemandeCreateView(props) {
  // console.log("props", props);
  const abortController = useMemo(() => new AbortController(), []);
  const { ChampsDemande } = useMemo(() => Data, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [moyen_paiements, setMoyens_payements] = useState([]);
  const [moyen_paiement, setMoyen_paiement] = useState("");
  const [pays, setPays] = useState("");
  const [champsDemandeData, setChampsDemandeData] = useState([]);
  const [champsDemandeObject, setChampsDemandeObject] = useState({});
  const [domiciliationData, setDomiciliationData] = useState({});
  // const [transitData, setTransitData] = useState("");
  const [tempData, setTempData] = useState("b");
  const [demandeId, setDemandeId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [montantSiege, setMontantSiege] = useState(0);
  const [montantCession, setMontantCession] = useState(0);
  const [montantCapital, setMontantCapital] = useState(0);
  const [stateLoad, setStateLoad] = useState(false);

  /* Formation */

  let tabFormation = {
    name: "cabinet-formation",
    question:
      "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)",
        price: 100_000,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Transit */

  let tabTrasit = {
    name: "cabinet-transit",
    question:
      "Souhaitez-vous d'être accompagné pour l'obtention du code import/export ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je me fais accompagner pour obtenir le code Import/Export (50 000 FCFA)",
        price: 50_000,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Transport */

  let tabTransport = {
    name: "transport",
    question:
      "Souhaitez-vous être accompagné par Legafrik pour vous immatriculer au Registre des transporteurs ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je me fais accompagner pour mon immatriculation (100 000 Fr CFA)",
        price: 100_000,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  };

  /* Option domicilie à Legafrik */
  let optionDomicili = {
    value: `Je domicilie à Legafrik ${
      pays.libelle && pays.libelle.includes("Cameroun")
        ? "(20 000 FCFA/mois)"
        : "(30 000 FCFA/mois)"
    } `,
    price: "",
  };
  /* Nouveau siege */

  let nouveau_siege = {
    name: "nouveau-siege",
    question: "",
    description: "",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /* Nouveau nom entreprise */

  let nouveau_nom_entreprise = {
    name: "nouveau-nom-entreprise",
    question: "",
    description: "Veuillez comfirmer le nom  actuel de votre entreprise",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * part ceder
   */

  let vendeur_part = {
    name: "vendeur-part",
    question: "",
    description: "Cession de parts ou actions ",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouveau president
   */

  let nouveau_president = {
    name: "nouveau-president",
    question: "",
    description:
      "Veuillez entrer les informations du nouveau président ou gerant",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouvelle_activite
   */
  let nouvelle_activite = {
    name: "nouvelle-activite",
    question: "",
    description: "Confirmez la liste actuelle de vos activités",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  /*
   * nouvelle_capital
   */
  let nouvelle_acapital = {
    name: "nouvelle-capital",
    question: " ",
    description: "Quel  est le montant du nouveau capital social ?",
    // type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  };

  const useTypeDemande = Hooks.useTypeDemande();
  const useDemande = Hooks.useDemande();
  const usePaiement = Hooks.usePaiement();
  // console.log("useDemande", useTypeDemande);

  const handleNextClick = (e, name, value) => {
    e.preventDefault();
    // console.log("handleNextClick", name, value);
    if (name === "associes") {
      let som = 0;
      const dataPars = JSON.parse(value);

      dataPars.map((associe) => {
        if (associe["montant-part-vendu"]) {
          som = som + JSON.parse(associe["montant-part-vendu"]);
        }
      });
      const result = Math.ceil(som * 0.03);
      if (result > 22000) {
        setMontantCession(Math.ceil(som * 0.03));
      } else {
        setMontantCession(22000);
      }
    }

    if (name === "nouveau-siege") {
      setMontantSiege(value);
    }
    if (name === "nouvelle-capital") {
      setMontantCapital(value);
    }

    const champsDemandeObjectCopy = { ...champsDemandeObject };
    champsDemandeObjectCopy[name] = value;

    setTempData(value);
    setChampsDemandeObject(champsDemandeObjectCopy);
    // console.log("setChampsDemandeObject", champsDemandeObjectCopy);

    setStep(step + 1);
  };

  const handleNextClicked = async (e, name, value) => {
    e.preventDefault();
    setStateLoad(true);
    if (value) {
      const result = await dataDataGreffeRccm(value.rccm);
      // console.log("result", result);

      const champsDemandeObjectCopy = { ...champsDemandeObject };

      champsDemandeObjectCopy["denomination"] =
        result.response.data[0].denomination;
      champsDemandeObjectCopy["capital-social"] =
        result.response.data[0].capital;
      champsDemandeObjectCopy["localisation-siege"] =
        result.response.data[0].siege;
      champsDemandeObjectCopy["activites"] = result.response.data[0].activites;
      champsDemandeObjectCopy["nouvelle-forme-juridique"] =
        result.response.data[0].forme_juridique;
      champsDemandeObjectCopy["numero-rccm"] = result.response.data[0].rccm;
      champsDemandeObjectCopy["ville-immatriculation"] =
        result.response.data[0].ville_immatriculation;
      // console.log("champsDemandeObjectCopy", champsDemandeObjectCopy);
      setTempData(result.response.data[0]);
      setChampsDemandeObject(champsDemandeObjectCopy);

      setStep(step + 7);
      setStateLoad(false);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    if (step === 0 || useDemande.isDisabled) return;
    setStep(step - 1);
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    setMoyen_paiement("");
  };

  const handleSetAmount = (val) => {
    usePaiement.setMontant(val);
    useDemande.setMontant_total(val);
  };

  const handleValidateClick = async (e) => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmClick = () => {
    createDemande();
    setIsConfirmModalOpen(false);
  };

  const createDemande = async () => {
    if (useDemande.isDisabled) return;
    useDemande.setIsDisabled(true);

    try {
      const { username, numero_telephone, email } = Utils.Auth.getUser();
      const lastname = username.split(" ")[0] ?? "";
      const firstname = username.split(lastname)[1] ?? "";

      champsDemandeObject["nom"] = lastname.trim();
      champsDemandeObject["prenoms"] = firstname.trim();
      champsDemandeObject["numero-telephone"] = numero_telephone;
      champsDemandeObject["adresse-mail"] = email;

      const { dossier } = await Services.UserService.getDossier(
        abortController.signal
      );
      // console.log("azerty", dossier);
      const payload = {
        type_demande_id: useDemande.type_demande_id,
        dossier_id: dossier.id,
        pays_id: pays.id,
        champs_demande: JSON.stringify(champsDemandeObject),
        montant_total: useDemande.montant_total,
      };

      // console.log("payload", payload);

      const { demande } = await Services.DemandeService.create(
        JSON.stringify(payload),
        abortController.signal
      );

      useDemande.setIsDisabled(false);
      setDemandeId(demande.id);
      setStep(step + 1);

      await Services.HubspotService.createLead(JSON.stringify(payload));
    } catch (error) {
      useDemande.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentSubmit = async (e, payment_data) => {
    // console.log("payment_data", payment_data);

    e.preventDefault();
    if (payment_data.id === 3) {
      if (usePaiement.isDisabled) return;
      useDemande.setIsDisabled(true);
      try {
        const { username, numero_telephone, email } = Utils.Auth.getUser();
        const lastname = username.split(" ")[0] ?? "";
        const firstname = username.split(lastname)[1] ?? "";

        champsDemandeObject["nom"] = lastname.trim();
        champsDemandeObject["prenoms"] = firstname.trim();
        champsDemandeObject["numero-telephone"] = numero_telephone;
        champsDemandeObject["adresse-mail"] = email;

        const { dossier } = await Services.UserService.getDossier(
          abortController.signal
        );
        // console.log("azerty", dossier);
        const payload = {
          type_demande_id: useDemande.type_demande_id,
          dossier_id: dossier.id,
          pays_id: pays.id,
          champs_demande: JSON.stringify(champsDemandeObject),
          montant_total: useDemande.montant_total,
        };

        // console.log("payload", payload);

        const { demande } = await Services.DemandeService.create(
          JSON.stringify(payload),
          abortController.signal
        );

        useDemande.setIsDisabled(false);
        setDemandeId(demande.id);
        // setStep(step + 1);

        await Services.HubspotService.createLead(JSON.stringify(payload));
        navigate("/commandes");
      } catch (error) {
        useDemande.setIsDisabled(false);
        if ("messages" in error) return; //Toast
      }
    }

    let payload = {
      type_paiement_id: 1,
      moyen_paiement_id: payment_data.moyen_paiement_id
        ? payment_data.moyen_paiement_id
        : moyen_paiement.id,
      demande_id: demandeId,
      montant: usePaiement.montant,
    };
    payment_data["pays"] = JSON.stringify(pays);

    try {
      usePaiement.setIsDisabled(true);

      payload["payment_data"] = JSON.stringify(payment_data);
      await Services.PaiementService.create(
        JSON.stringify(payload),
        abortController.signal
      );

      navigate("/commandes");
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentMethodClick = async (e, moyen_paiements) => {
    // console.log("moyen_paiements", moyen_paiements);
    e.preventDefault();

    setIsModalOpen(true);
    setMoyen_paiement(moyen_paiements);
    const payment_data = {
      id: moyen_paiements.id,
      currency: pays.monnaie,
      moyen_paiement_id: moyen_paiements.id,
      description: "This is test payment",
    };
    let money;
    if (moyen_paiement.libelle === "Payer plus tard") {
      money = 0;
    } else {
      money = usePaiement.montant;
    }
    const payload = {
      id: Math.round(Math.random() * 1000000).toString(),

      amount: money,
      payment_data: payment_data,
      moyen_paiement_id: moyen_paiements.id,
    };
    // console.log('payload',payload);
    if (moyen_paiement.libelle?.includes("Mobile")) {
      try {
        await new CinetPaySerivce().makePayment(payload);
        await handlePaymentSubmit(e, payment_data);
      } catch (error) {
        usePaiement.setIsDisabled(false);
        if ("messages" in error) return; //Toast
      }
    }

    await handlePaymentSubmit(e, payment_data);
    // navigate("/new-password");
  };

  const initialize = useCallback(async () => {
    window.localStorage.removeItem("cst");

    try {
      useDemande.setType_demande_id(id);
      const { pays } = await useTypeDemande.getTypeDemande(
        id,
        abortController.signal
      );
      // console.log("pays", pays);

      setPays(pays);

      const { moyen_paiements } = await Services.MoyenPaiementService.getAll(
        abortController.signal
      );
      setMoyens_payements(moyen_paiements);
    } catch (error) {
      if ("messages" in error) return;
      //toast
    }
  }, [abortController, id]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  /* Commune de l'entreprise */

  useEffect(() => {
    const reform = champsDemandeData.map((data) => {
      let dataOption = data.options.filter(
        (val, index) => !val.value.includes("domicilie à Legafrik")
      );
      return { ...data, options: [...dataOption] };
    });
    if (typeof tempData === "string" && tempData.includes("Hors")) {
      const reformat = reform.map((data) => {
        let dataOption = data.options.filter(
          (val, index) => !val.value.includes("domicilie à Legafrik")
        );
        return { ...data, options: [...dataOption] };
      });
      setChampsDemandeData(reformat);
    }

    /* Recuperation de prix en fonction de cummune */

    if (parseInt(tempData) > 0) {
      const champsDemandeDataCopy = reform.map((data, index) => {
        if (data.name === "localisation-siege") {
          if (pays.code !== "RDC") {
            data.options.unshift(optionDomicili);
          }
        }
        return { ...data, options: [...data.options] };
      });
      setChampsDemandeData(champsDemandeDataCopy);
    }
  }, [tempData]);

  /* fin Commune de l'entreprise */

  /* Ajout et suppression des options Activite en fonction du choix de l'utilisation*/

  function remove_duplicates_es6(arr) {
    return arr.filter((obj, index) => {
      return index === arr.findIndex((o) => obj.name === o.name);
    });
  }

  /* fin Ajout et suppression des options Activite en fonction du choix de l'utilisation*/

  useEffect(() => {
    const localisationSiege = champsDemandeObject["localisation-siege"];

    if (
      localisationSiege !== undefined &&
      !localisationSiege.includes("Legafrik")
    ) {
      champsDemandeData.forEach((data, index) => {
        // console.log("champsDemandeData10", data);
        if (data.name !== "gestion-domiciliation") return;
        setDomiciliationData({ index, object: data });
      });
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });
      const domiciliationIndex = champsDemandeDataCopy.findIndex(
        (data) => data.name === "gestion-domiciliation"
      );

      if (domiciliationIndex < 0) return;

      champsDemandeDataCopy.splice(domiciliationIndex, 1);
      setChampsDemandeData(champsDemandeDataCopy);
    } else {
      const domiciliationDataIndex = domiciliationData.index;

      if (!domiciliationDataIndex || domiciliationDataIndex < 0) return;
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      champsDemandeDataCopy.splice(
        domiciliationDataIndex,
        0,
        domiciliationData.object
      );

      setChampsDemandeData(champsDemandeDataCopy);
      setDomiciliationData({});
    }
  }, [champsDemandeObject["localisation-siege"]]);

  useEffect(() => {
    if (!pays.code) return;

    const LIBELLE = useTypeDemande.libelle;
    // console.log("useTypeDemande", LIBELLE);
    const CODE_PAYS = pays.code.toUpperCase();
    // console.log("CODE_PAYS", ChampsDemande[CODE_PAYS]);

    if (LIBELLE.includes("SARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARL]);
    if (LIBELLE.includes("SARLU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SUARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SAS"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);

    if (LIBELLE.includes("SCI commerciale"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);

    if (LIBELLE.includes("SCI civile"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SCI]);

    if (LIBELLE.includes("SASU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SASU]);
    if (LIBELLE.includes("ONG"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ONG]);
    if (LIBELLE.includes("Association"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ASSOCIATION]);
    if (LIBELLE.includes("Fondation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].FONDATION]);
    if (LIBELLE.includes("Domiciliation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DOMICILIATION]);
    if (LIBELLE.includes("Marque"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DEPOT_MARQUE]);
    if (LIBELLE.includes("modification") || LIBELLE.includes("statutaire"))
      setChampsDemandeData([
        ...ChampsDemande[CODE_PAYS].MODIFICATION_STATUTAIRE,
      ]);
    if (LIBELLE.includes("statutaire") || LIBELLE.includes("Statutaire"))
      setChampsDemandeData([
        ...ChampsDemande[CODE_PAYS].MODIFICATION_STATUTAIRE,
      ]);
    if (LIBELLE.includes("SCOOP"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);

    if (LIBELLE.includes("individuelle"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].INDIVIDUELLE]);

    // if (LIBELLE.includes("Recouvrement"))
    // setChampsDemandeData([...ChampsDemande[CODE_PAYS].RECOUVREMENT]);

    setStep(1);
    // console.log("ChampsDemande[CODE_PAYS].INDIVIDUELLE",ChampsDemande[CODE_PAYS].INDIVIDUELLE);
  }, [useTypeDemande.libelle, ChampsDemande, pays.code]);

  useEffect(
    () => {
      // if (!pays.code) return;

      // const LIBELLE = useTypeDemande.libelle;
      // const CODE_PAYS = pays;
      // console.log('CODE_PAYS',pays);
      const sectuerActivite = champsDemandeObject["secteur-activite"];

      const reform = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      if (pays.code?.includes("CI")) {
        if (
          sectuerActivite !== undefined &&
          !tempData.includes("formation") &&
          !tempData.includes("Transit") &&
          !tempData.includes("Transport")
        ) {
          const champsDemandeDataCopyed = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport")
          );

          setChampsDemandeData(champsDemandeDataCopyed);
        }
        if (sectuerActivite !== undefined && tempData.includes("formation")) {
          const reformat = reform.filter(
            (data) =>
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport") &&
              !data.name.includes("cabinet-formation")
          );
          reformat.push(tabFormation);
          setChampsDemandeData(reformat);
        }

        if (sectuerActivite !== undefined && tempData.includes("Transit")) {
          const reformated = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("transport") &&
              !data.name.includes("cabinet-transit")
          );
          reformated.push(tabTrasit);
          setChampsDemandeData(reformated);
        }

        if (sectuerActivite !== undefined && tempData.includes("Transport")) {
          const reformatedTransport = reform.filter(
            (data) =>
              !data.name.includes("cabinet-formation") &&
              !data.name.includes("cabinet-transit") &&
              !data.name.includes("transport")
          );
          reformatedTransport.push(tabTransport);
          setChampsDemandeData(reformatedTransport);
        }
      }
    },
    [champsDemandeObject["secteur-activite"]],
    tempData,
    pays.code
  );

  useEffect(() => {
    const myTab = JSON.parse(window.localStorage.getItem("cst"));
    let tabChampdata = champsDemandeData.map((data) => {
      return { ...data, options: [...data.options] };
    });

    if (pays.code?.includes("CI")) {
      let tableObject = [];
      if (myTab) {
        if (myTab.includes("Je change le nom de  mon entreprise")) {
          tableObject.push(nouveau_nom_entreprise);
        }

        if (myTab.includes("Je modifie mes activités")) {
          tableObject.push(nouvelle_activite);
        }

        if (myTab.includes("Je transfère mon siège social")) {
          tableObject.push(nouveau_siege);
        }

        if (myTab.includes("augmente mon capital social")) {
          tableObject.push(nouvelle_acapital);
        }

        if (myTab.includes("Je nomme un nouveau Gérant/Président")) {
          tableObject.push(nouveau_president);
        }

        if (myTab.includes("Je cède des parts")) {
          tableObject.push(vendeur_part);
        }

        tabChampdata.push(...tableObject);

        tabChampdata = remove_duplicates_es6(tabChampdata);

        if (!myTab.includes("Je change le nom de  mon entreprise")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-nom-entreprise"
          );
        }

        if (!myTab.includes("Je modifie mes activités")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouvelle-activite"
          );
        }
        if (!myTab.includes("Je transfère mon siège social")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-siege"
          );
        }

        if (!myTab.includes("augmente mon capital social")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouvelle-capital"
          );
        }

        if (!myTab.includes("Je nomme un nouveau Gérant/Président")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "nouveau-president"
          );
        }

        if (!myTab.includes("Je cède des parts")) {
          tabChampdata = tabChampdata.filter(
            (item) => item.name !== "vendeur-part"
          );
        }

        // setMyTabData(tabChampdata);

        // console.log("tabData", tabData);

        setChampsDemandeData(tabChampdata);
      }
    }
  }, [champsDemandeObject["modification-du-statut"]]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Nouvelle commande "{useTypeDemande.libelle} {pays.libelle}"
      </h2>
      {champsDemandeData && champsDemandeData.length > 0 ? (
        <div className="intro-y box py-10 sm:py-20">
          <Components.ProgressBar value={step} max={champsDemandeData.length} />
          {stateLoad ? (
            <Spinner />
          ) : (
            <>
              {champsDemandeData.map((champsDemandeDataItem, index) => {
                return (
                  <Fragment key={index}>
                    <Components.DemandeStep
                      step={step}
                      index={index + 1}
                      length={champsDemandeData.length}
                      demande={useTypeDemande.libelle}
                      champsDemandeData={champsDemandeDataItem}
                      handleNextClick={handleNextClick}
                      handleBackClick={handleBackClick}
                      handleNextClicked={handleNextClicked}
                      champsDemandeObject={champsDemandeObject}
                    />
                  </Fragment>
                );
              })}
            </>
          )}

          {champsDemandeData.length > 0 &&
          step - champsDemandeData.length === 1 ? (
            <Components.Cart
              productList={champsDemandeData}
              selectedProductList={Object.keys(champsDemandeObject).map(
                (key) => {
                  return { [key]: champsDemandeObject[key] };
                }
              )}
              handleRemoveProduct={null}
              handleBackClick={handleBackClick}
              handleValidateClick={handleValidateClick}
              isDisabled={useDemande.isDisabled}
              setAmount={handleSetAmount}
              amount={usePaiement.montant}
              currency={pays.monnaie}
              demande={useTypeDemande.libelle}
              montantSiege={montantSiege}
              montantCapital={montantCapital}
              montantCession={montantCession}
              moyen_paiements={moyen_paiements}
              handlePaymentClick={handlePaymentMethodClick}
            />
          ) : null}

          {champsDemandeData.length > 0 &&
          step - champsDemandeData.length === 2 ? (
            <Components.Payment
              moyen_paiements={moyen_paiements}
              currency={pays.monnaie}
              handlePaymentClick={handlePaymentMethodClick}
              amount={usePaiement.montant}
            />
          ) : null}
        </div>
      ) : (
        <div className="flex items-center justify-center text-xl font-medium intro-y box py-10 sm:py-20">
          Formulaire indisponible pour le moment
        </div>
      )}
      {isModalOpen &&
      (moyen_paiement.libelle.toLowerCase().includes("Stripe") ||
        moyen_paiement.libelle.toLowerCase().includes("carte")) ? (
        <Components.Modal
          handleModalClose={handleModalClose}
          title={"Paiement par Stripe"}
          handleModalValidate={null}
        >
          <Elements stripe={stripePromise} usePaiement={usePaiement}>
            <Components.Stripe.CheckoutForm
              usePaiement={usePaiement}
              handlePaymentSubmit={handlePaymentSubmit}
              currency={pays.monnaie}
              demande={demandeId}
            />
          </Elements>
        </Components.Modal>
      ) : null}
      {isConfirmModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsConfirmModalOpen(false)}
          title={"Confirmation"}
          handleModalValidate={() => handleConfirmClick()}
          isControlVisible={true}
        >
          Voulez-vous confirmer votre commande?
        </Components.Modal>
      ) : null}
    </div>
  );
}
