import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Components } from "../components";
import { CI } from "../components/forms/questionnaires/ci";
import { SigleStep } from "../components/forms/questionnaires/SigleStep";
import logoImg from "../assets/images/logo-legafrik.png";
import call from "../assets/images/call.svg";
import fileDocument from "../assets/images/fileDocument.svg";
import { Utils } from "../utils";
import { DemandeCreateViewSuivie } from "./DemandeCreateViewSuivie";
import { Associes } from "../components/forms/questionnaires/Associes";
import { ActiviteStep } from "../components/forms/questionnaires/ActiviteStep";
import { RecapStepSarlV2 } from "../components/forms/questionnaires/ci/sarl/RecapStepSarlV2";
// import { AdresseOneStep } from "../components/forms/questionnaires/AdresseOneStep";
// import { AdresseTwoStep } from "../components/forms/questionnaires/AdresseTwoStep";
import { CapitalStep } from "../components/forms/questionnaires/CapitalStep";
import { GerantStep } from "../components/forms/questionnaires/GerantStep";
import { PresidentStep } from "../components/forms/questionnaires/PresidentStep_ci_sn_bf_bn";
import { DirecteurStep } from "../components/forms/questionnaires/DirecteurStep";
import { RecapStepSasV2 } from "../components/forms/questionnaires/ci/sarl/RecapStepSasV2";
import { SigleStepOng } from "../components/forms/questionnaires/SigleStepOng";
import { AssociesStatutaire } from "../components/forms/questionnaires/ci/statutaire/AssociesStatutaire";
import { InfoAssemble } from "../components/forms/questionnaires/ci/statutaire/InfoAssemble";
import { InfoModification } from "../components/forms/questionnaires/ci/statutaire/InfoModification";
import { RecapStepStatutaire } from "../components/forms/questionnaires/ci/statutaire/RecapStepStatutaire";
import Swal from "sweetalert2";

function QuestionnaireSuivi() {
  //   const { type_demande } = useParams();
  // const abortController = useMemo(() => new AbortController(), []);

  const { state } = useLocation();
  console.log("state", state);

  const [useDemande,] = useState();
  const [champs_demandes, setChamps_demandes] = useState();
  const [, setStepIndividuelle] = useState();
  // const [indexStep, setIndexStep] = useState(0);
  // const [timing, setTiming] = useState(5);
  const [champs_lead, setChamps_lead] = useState({});
  const [dataDemandes, setDataDemandes] = useState(null);
  const [type_demandes, setType_demandes] = useState();

  const [step, setStep] = useState(1);

  const [champs_questionnaireObject, setChamps_questionnaireObject] = useState(
    {}
  );
  const dataFormat = {
    lead: {},
    questionnaire: {},
  };

  const tiltlQuestionnaire = "Veuillez remplir le questionnaire ci-dessous";

  useEffect(() => {
    fetch(`${process.env.REACT_APP_HOST}/api/check/dossier/${state.reference}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        // console.log("data.data", data);

        setDataDemandes(data);
        setType_demandes(data.data.type_demande);
        setChamps_demandes(Utils.String.parseJson(data.data.champs_demande));
        // if (data.status.includes("questionnaire")) {
        //   setStep(step + 1);
        // }
      });
  }, [state.reference]);
  // const CODE_PAYS = props?.data?.useDemande.pays?.code ?? "";

  // console.log("champs_demandes", champs_demandes);

  const LIBELLE_TYPE_DEMANDE = type_demandes?.libelle
    ? type_demandes.libelle.split(" ")[
        type_demandes.libelle.split(" ").length - 1
      ]
    : "SARL";
  const QuestionnaireFormsByPaysByService = CI
    ? CI[LIBELLE_TYPE_DEMANDE]
    : CI.SARL;

  const handleQuetionnaireNextClick = (e, name, value, isEnd = false) => {
    e.preventDefault();

    if (name === "associes") {
      setStepIndividuelle(name);
    }
    const champs_questionnaireObjectCopy = { ...champs_questionnaireObject };

    if (name !== "infos") {
      champs_questionnaireObjectCopy[name] = value;
    } else {
      champs_questionnaireObjectCopy["activites"] = value["activites"];

      champs_questionnaireObjectCopy["sigle"] = JSON.stringify(value["sigle"]);
    }
    console.log(
      "champs_questionnaireObjectCopy",
      champs_questionnaireObjectCopy
    );

    setChamps_questionnaireObject(champs_questionnaireObjectCopy);

    if (isEnd) return;

    setStep(step + 1);
  };

  const handleQuestionnaireBackClick = (e, value) => {
    // console.log("e", e);
    e.preventDefault();

    if (step === 1) return;

    // if (useDemande.type_demande?.libelle.includes("SAS")) {
    //   setStep(step - 2);
    // } else if (
    //   stepIndividuelle === "associes" &&
    //   useDemande.type_demande?.libelle.includes("individuelle")
    // ) {
    //   //console.log("stepIndividuelle", stepIndividuelle);
    //   setStep(step - 3);
    // } else {
    // }
    setStep(step - 1);
  };

  const handleValidateClick = (chamDemandes) => {
    console.log("chamDemandes", chamDemandes);
    setChamps_lead(chamDemandes);
  };

  const handleQuestionnaireSubmit = async (e, name, value) => {
    e.preventDefault();

    const champs_questionnaireObjectCopy = { ...champs_questionnaireObject };
    champs_questionnaireObjectCopy[name] = value;

    setChamps_questionnaireObject(champs_questionnaireObjectCopy);
    // useDemande.setIsDisabled(true);
    // console.log("champs_lead", champs_lead);
    // console.log("dataDemandes", dataDemandes);

    dataFormat["lead"].pays_id = dataDemandes.data.pays_id;
    dataFormat["lead"].montant_total = dataDemandes.data.montant_total;
    dataFormat["lead"].type_demande_id = dataDemandes.data.type_demande_id;
    dataFormat["lead"].champs_demande = JSON.stringify(champs_lead);

    dataFormat["lead"].dossier_id = dataDemandes.data.dossier_id;
    dataFormat["lead"].organisation_id = dataDemandes.data.organisation_id;
    dataFormat["questionnaire"].champs_questionnaire = JSON.stringify(
      champs_questionnaireObjectCopy
    );
    // console.log("dataFormat", dataFormat);
    // return;
    try {
      fetch(
        `${process.env.REACT_APP_HOST}/api/submit/demande/${dataDemandes.data.id}`,
        {
          method: "PUT",
          body: JSON.stringify(dataFormat),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          // console.log("PUT===>", data);
          if (data.success) {
            Swal.fire({
              allowOutsideClick: false,
              title: "Information",
              text: "Vos informations sont bien enregistrés ",
              icon: "info",
              confirmButtonColor: "#006699",
              confirmButtonText: "Continuer la procédure",
            }).then((result) => {
              window.location.reload();
            });
          }
        });
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    } finally {
      // useDemande.setIsDisabled(false);
    }
  };

  const handleNextClicked = (steped) => {
    setStep(step + 1);
  };
  // console.log("champs_demandes", champs_demandes);

  if (dataDemandes) {
    if (
      dataDemandes.message.includes("Signature") ||
      dataDemandes.status === "impaye"
    ) {
      return (
        <div className="my-32 mx-auto md:w-1/2 lg:w-1/3 text-center box p-4">
          <div className="flex justify-center">
            <img
              alt="Midone Tailwind HTML Admin Template"
              className="w-24 -intro-x"
              src={logoImg}
            />
          </div>
          <div className=" ">
            <p className="-intro-x text-dark-1 font-medium text-2xl leading-tight my-5">
              Votre dossier est à l'étape :
            </p>
            <p className="-intro-x text-danger font-medium text-2xl leading-tight">
              {dataDemandes.message}
              {dataDemandes.message.includes("paiement") ? (
                <img
                  src={call}
                  alt="call.svg"
                  className="w-24  mx-auto my-6 animate-bounce "
                />
              ) : (
                <img
                  src={fileDocument}
                  alt="fileDocument.svg"
                  className="w-24  mx-auto my-6 animate-bounce "
                />
              )}
            </p>
          </div>
        </div>
      );
    } else {
      if (dataDemandes.data && type_demandes.libelle.includes("individuelle")) {
        return (
          /**
           * Questionnaire
           */
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <SigleStep
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                stated={"suivie"}
                nomSociete={
                  champs_demandes ? champs_demandes["denomination"][0] : ""
                }
              />
              <Associes
                index={3}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                demande={"individuelle"}
                nombreAssocies={champs_demandes["nombre-associes"]}
              />
              <ActiviteStep
                index={4}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <RecapStepSarlV2
                index={5}
                step={step}
                handleSubmit={handleQuestionnaireSubmit}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                champsDemandeObject={champs_demandes}
                questionnaireObject={champs_questionnaireObject}
                demande={"individuelle"}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("SARL")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
            </div>
            <QuestionnaireFormsByPaysByService.SigleStep
              index={2}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              nomSociete={
                champs_demandes
                  ? champs_demandes["denomination"][0]
                  : "Non spécifié"
              }
            />

            <Components.OptionalStep
              isOptional={
                step === 3 &&
                champs_demandes["localisation-siege"]?.includes("Legafrik")
              }
              callback={() =>
                handleQuetionnaireNextClick(new Event("click"), "adresse", "")
              }
            >
              <QuestionnaireFormsByPaysByService.AdresseOneStep
                index={3}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
            </Components.OptionalStep>

            <Components.OptionalStep
              isOptional={
                step === 4 &&
                champs_demandes["localisation-siege"]?.includes("Legafrik")
              }
              callback={() =>
                handleQuetionnaireNextClick(new Event("click"), "adresse", "")
              }
            >
              <QuestionnaireFormsByPaysByService.AdresseTwoStep
                index={4}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
              />
            </Components.OptionalStep>

            <QuestionnaireFormsByPaysByService.Associes
              index={5}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              demande={"SARL"}
              nombreAssocies={champs_demandes["nombre-associes"]}
              chamDemandes={champs_demandes}
            />

            <QuestionnaireFormsByPaysByService.CapitalStep
              index={6}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              capitalSocial={
                champs_lead["capital-social"]
                  ? champs_lead["capital-social"]
                  : champs_demandes["capital-social"]
              }
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />

            <QuestionnaireFormsByPaysByService.ActiviteStep
              index={7}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            />

            <QuestionnaireFormsByPaysByService.GerantStep
              index={8}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />

            <QuestionnaireFormsByPaysByService.RecapStepSarlV2
              index={9}
              step={step}
              demande={"SARL"}
              handleSubmit={handleQuestionnaireSubmit}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              champsDemandeObject={champs_demandes}
              questionnaireObject={champs_questionnaireObject}
            />
          </div>
        );
      } else if (type_demandes.libelle.includes("SARLU")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
            </div>
            <SigleStep
              index={2}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              nomSociete={
                champs_demandes ? champs_demandes["denomination"][0] : ""
              }
            />

            <Components.OptionalStep
              isOptional={
                step === 3 &&
                champs_demandes["localisation-siege"]?.includes("Legafrik")
              }
              callback={() =>
                handleQuetionnaireNextClick(new Event("click"), "adresse", "")
              }
            >
              <QuestionnaireFormsByPaysByService.AdresseOneStepSarlUni
                index={3}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
            </Components.OptionalStep>

            <Components.OptionalStep
              isOptional={
                step === 4 &&
                champs_demandes["localisation-siege"]?.includes("Legafrik")
              }
              callback={() =>
                handleQuetionnaireNextClick(new Event("click"), "adresse", "")
              }
            >
              <QuestionnaireFormsByPaysByService.AdresseTwoStepSarlUni
                index={4}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
              />
            </Components.OptionalStep>

            <QuestionnaireFormsByPaysByService.AssocieTypesSarlUni
              index={5}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              demande={"SARLU"}
              nombreAssocies={champs_demandes["nombre-associes"]}
            />

            <QuestionnaireFormsByPaysByService.AssociesSarlUni
              index={5}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              nombreAssocies={champs_demandes["nombre-associes"]}
              capitalSocial={champs_demandes["capital-social"] ?? ""}
              associeTypes={champs_questionnaireObject["associeTypes"] ?? "[]"}
            />

            <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
              index={6}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            />

            <GerantStep
              index={7}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />

            <RecapStepSarlV2
              index={8}
              step={step}
              demande={"SARLU"}
              handleSubmit={handleQuestionnaireSubmit}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              champsDemandeObject={champs_demandes}
              questionnaireObject={champs_questionnaireObject}
            />
          </div>
        );
      } else if (type_demandes.libelle.includes("SASU")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <SigleStep
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                nomSociete={champs_demandes["denomination"]}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <Components.OptionalStep
                isOptional={
                  step === 3 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseOneStepSasUni
                  index={3}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                />
              </Components.OptionalStep>

              <Components.OptionalStep
                isOptional={
                  step === 4 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseTwoStepSasUni
                  index={4}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
                />
              </Components.OptionalStep>

              <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                nombreAssocies={champs_demandes["nombre-associes"]}
              />

              <QuestionnaireFormsByPaysByService.AssociesSasUni
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                nombreAssocies={champs_demandes["nombre-associes"]}
                capitalSocial={champs_demandes["capital-social"] ?? ""}
                associeTypes={
                  champs_questionnaireObject["associeTypes"] ?? "[]"
                }
              />

              <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <PresidentStep
                index={8}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <DirecteurStep
                index={9}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <RecapStepSasV2
                index={10}
                step={step}
                demande={"SASU"}
                handleSubmit={handleQuestionnaireSubmit}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                champsDemandeObject={Utils.String.parseJson(
                  useDemande.champs_demande
                )}
                questionnaireObject={champs_questionnaireObject}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("SAS")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <SigleStep
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                stated={"suivie"}
                nomSociete={
                  champs_demandes ? champs_demandes["denomination"][0] : ""
                }
              />

              {/* <Components.OptionalStep
                isOptional={
                  step === 3 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                </Components.OptionalStep> */}
              <QuestionnaireFormsByPaysByService.AdresseOneStepSas
                index={3}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <Components.OptionalStep
                isOptional={
                  step === 4 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
                  index={4}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
                />
              </Components.OptionalStep>

              <Associes
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                nombreAssocies={champs_demandes["nombre-associes"]}
              />

              <QuestionnaireFormsByPaysByService.ActiviteStepSas
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <CapitalStep
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                capitalSocial={
                  champs_demandes["capital-social"]
                    ? champs_demandes["capital-social"]
                    : champs_lead["capital-social"]
                }
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <PresidentStep
                index={8}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <DirecteurStep
                index={9}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <RecapStepSasV2
                index={10}
                step={step}
                demande={"SAS"}
                handleSubmit={handleQuestionnaireSubmit}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                champsDemandeObject={champs_demandes}
                questionnaireObject={champs_questionnaireObject}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("Association")) {
        // else  {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <QuestionnaireFormsByPaysByService.SigleStepAssociation
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                nomSociete={champs_demandes["denomination"]}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
                index={3}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
                index={4}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
              >
                <Components.OptionalStep
                  isOptional={
                    step === 4 &&
                    champs_demandes["localisation-siege"]?.includes("Legafrik")
                  }
                  callback={() =>
                    handleQuetionnaireNextClick(
                      new Event("click"),
                      "adresse",
                      ""
                    )
                  }
                ></Components.OptionalStep>
              </QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation>
              <QuestionnaireFormsByPaysByService.ActiviteStepAssociation
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.PresidentAssociation
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.TresorierAssociation
                index={8}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                capitalSocial={champs_demandes["capital-social"] ?? ""}
              />
              <QuestionnaireFormsByPaysByService.BureauAssociation
                index={9}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.CommissaireAssociation
                index={10}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.CommissaireAssociationAdjoint
                index={11}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.DureAssociation
                index={12}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.MontantAssociation
                index={13}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.RecapStepAssopciation
                index={14}
                step={step}
                demande={"Association"}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                questionnaireObject={champs_questionnaireObject}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("ONG")) {
        // else  {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <SigleStepOng
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                nomSociete={champs_demandes["denomination"]}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <Components.OptionalStep
                isOptional={
                  step === 3 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseOneStepOng
                  index={3}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                />
              </Components.OptionalStep>

              <Components.OptionalStep
                isOptional={
                  step === 4 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseTwoStepOng
                  index={4}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
                />
              </Components.OptionalStep>
              <QuestionnaireFormsByPaysByService.ActiviteStepOng
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.PresidentOng
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.SecretaireGeneralOng
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.TresorierOng
                index={8}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                capitalSocial={champs_demandes["capital-social"] ?? ""}
              />

              <QuestionnaireFormsByPaysByService.BureauOng
                index={9}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.CommissaireOng
                index={10}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />

              <QuestionnaireFormsByPaysByService.CommissaireOngAjoint
                index={11}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.DureOng
                index={12}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.MontantOng
                index={13}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.RecapStepOng
                index={14}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                questionnaireObject={champs_questionnaireObject}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
                demande={"ONG"}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("Fondation")) {
        // else  {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <QuestionnaireFormsByPaysByService.SigleStepFondation
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                nomSociete={champs_demandes["denomination"]}
              />

              <Components.OptionalStep
                isOptional={
                  step === 3 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseOneStepFondation
                  index={3}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                />
              </Components.OptionalStep>

              <Components.OptionalStep
                isOptional={
                  step === 4 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseTwoStepFondation
                  index={4}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
                />
              </Components.OptionalStep>
              <QuestionnaireFormsByPaysByService.ActiviteStepFondation
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              <QuestionnaireFormsByPaysByService.PresidentFondation
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.SecretaireGeneralFondation
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.TresorierFondation
                index={8}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                capitalSocial={champs_demandes["capital-social"] ?? ""}
              />

              <QuestionnaireFormsByPaysByService.BureauFondation
                index={9}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.CommissaireFondation
                index={10}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.CommissaireFondationAdjoint
                index={11}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                associes={champs_questionnaireObject["associes"] ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.DureFondation
                index={12}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.MontantFondation
                index={13}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
              />
              <QuestionnaireFormsByPaysByService.RecapStepFondation
                index={14}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                questionnaireObject={champs_questionnaireObject}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
                demande={"Fondation"}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("Recouvrement")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
              <QuestionnaireFormsByPaysByService.SigleStepRecouvre
                index={2}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                nomSociete={champs_demandes["entreprise-debitrice"]}
              />

              <Components.OptionalStep
                isOptional={
                  step === 3 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseOneStepRecouvre
                  index={3}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                />
              </Components.OptionalStep>

              <Components.OptionalStep
                isOptional={
                  step === 4 &&
                  champs_demandes["localisation-siege"]?.includes("Legafrik")
                }
                callback={() =>
                  handleQuetionnaireNextClick(new Event("click"), "adresse", "")
                }
              >
                <QuestionnaireFormsByPaysByService.AdresseTwoStepRecouvre
                  index={4}
                  step={step}
                  handleNextClick={handleQuetionnaireNextClick}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  adresseNew={champs_questionnaireObject["adresse"] ?? "{}"}
                />
              </Components.OptionalStep>

              {/* <ActiviteStepRecouv
              index={4}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            /> */}
              <QuestionnaireFormsByPaysByService.FacturesRecouvre
                index={5}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />

              <QuestionnaireFormsByPaysByService.CommentRecouvre
                index={6}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              />
              {/* <SecretaireGeneralRecouvre
              index={5}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            />
    
            <TresorierRecouvre
              index={6}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              capitalSocial={champs_demandes["capital-social"]}
            />
    
            <BureauRecouvre
              index={7}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            />
    
            <CommissaireRecouvre
              index={8}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />
    
            <DureRecouvre
              index={9}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              handleSubmit={handleSubmit}
              dataGlobal={champs_demandes ?? "[]"}
            />
            <MontantRecouvre
              index={10}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              handleSubmit={handleSubmit}
              dataGlobal={champs_demandes ?? "[]"}
            /> */}
              <QuestionnaireFormsByPaysByService.RecapStepRecouvre
                index={7}
                step={step}
                handleNextClick={handleQuetionnaireNextClick}
                handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                questionnaireObject={champs_questionnaireObject}
                handleSubmit={handleQuestionnaireSubmit}
                dataGlobal={champs_demandes ?? "[]"}
                demande={"Recouvrement"}
              />
            </div>
          </div>
        );
      } else if (type_demandes.libelle.includes("statutaire")) {
        return (
          <div className="grid grid-cols-12 mt-5">
            {/* <SigleStep
              index={1}
              step={step}Sas
              handleNextClick={handleNextClick}
              nomSociete={champs_demandes["denomination"]}
            /> */}
            {step && step === 1 && (
              <DemandeCreateViewSuivie
                index={1}
                paraPays={state.paraPays}
                type_demandes={type_demandes}
                champs_demandes={champs_demandes}
                handleNextClick={handleNextClicked}
                handleValidateClick={handleValidateClick}
              />
            )}
            <div className="col-span-12">
              {step > 1 ? (
                <h1 className="text-3xl font-bold text-center my-5">
                  {tiltlQuestionnaire}
                </h1>
              ) : null}
            </div>

            <AssociesStatutaire
              index={2}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              nomSociete={champs_demandes["denomination"][0]}
            />

            {/* <AdresseTwoStepSas
                index={3}
                step={step}
                handleNextClick={handleNextClick}
                handleQuestionnaireBackClick={
                  handleQuestionnaireBackClick
                }
                adresseNew={
                  champs_questionnaireObject["adresse"] ?? "{}"
                }
              /> */}

            <InfoAssemble
              index={3}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              nombreAssocies={champs_demandes["nombre-associes"]}
              type_demande={type_demandes}
            />

            <InfoModification
              index={4}
              step={step}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
              chamDemande={champs_demandes}
            />
            {/* <PresidentStep
              index={4}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            /> */}

            {/* <ActiviteStepSas
              index={5}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
            />
    
            <CapitalStep
              index={6}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              capitalSocial={champs_demandes["capital-social"]}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />
    
           
            <DirecteurStep
              index={8}
              step={step}
              handleNextClick={handleNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              associes={champs_questionnaireObject["associes"] ?? "[]"}
            />
    */}
            <RecapStepStatutaire
              index={5}
              step={step}
              handleSubmit={handleQuestionnaireSubmit}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              champsDemandeObject={champs_demandes}
              questionnaireObject={champs_questionnaireObject}
              dataGlobal={champs_demandes ?? "[]"}
              demande={"Recouvrement"}
            />
          </div>
        );
      }
    }
  }
  return <div>Pas de questionnaire</div>;
}

export default QuestionnaireSuivi;
