import { Api } from "./Api";

const ENPOINTS = {
  Paiement: "paiements",
  PaiementIntent: "create-payment-intent",
};

const getAll = (signal) => {
  return Api.get(ENPOINTS.Paiement, signal);
};

const getById = (id, signal) => {
  return Api.get(`${ENPOINTS.Paiement}/${id}`, signal);
};

const getPaymentIntent = (token, payload, signal) => {
  return Api.post(`user/${ENPOINTS.PaiementIntent}`, token, payload, signal);
};
const getPaymentIntents = (token, payload, signal) => {
  return Api.posts(`user/${ENPOINTS.PaiementIntent}`, token, payload, signal);
};

const create = (payload, signal) => {
  // console.log('payload',payload);
  return Api.post(`user/${ENPOINTS.Paiement}`, payload, signal);
};

const creates = (token, payload, signal) => {
  return Api.posts(`user/${ENPOINTS.Paiement}`, token, payload, signal);
};

const update = (id, payload, signal) => {
  return Api.put(`${ENPOINTS.Paiement}/${id}`, payload, signal);
};
const destroy = (id, signal) => {
  return Api.erase(`${ENPOINTS.Paiement}/${id}`, signal);
};

export const PaiementService = {
  getAll,
  getById,
  getPaymentIntent,
  getPaymentIntents,
  create,
  creates,
  update,
  destroy,
};
